
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import { queryVerifyCustomerHttp } from '~/api/cusSer'
import { addBlackHttp } from '~/api/black'
@Component({
  name: "floatMenu5",
  components: {
    newImg2: require("~/components/comm/newImg/index2.vue").default
  },
})
export default class floatMenu8 extends Vue {
  @Prop({default:()=>{}}) preSaleSerObj_?: any
  @Prop(String) snAndIsMyGoods_?: any
  public openOnlineService:any
  private openUrl:any
  private mobileRegular:any
  private qqRegular:any
  private show: boolean = true
  private show1: number = 1;
  private isShowCusQr : boolean = false 
  private isShowGroupQr : boolean = false 
  private QRCode: any;
  private hostQr: string = '';
  private isCheckCurstomer:boolean = true
  private inputStr: string = ''
  private inputWxStr: string = ''   // 因为刚开始只考虑了qq和黑号 微信后来添加 单独处理
  private isShowMask: boolean = false
  mounted() {
    this.qRCodeFn();
  }
  @Emit()
  //返回顶部
  toTop() {
    window.scrollTo(0, 0);
  }
  @Emit()
  erweimaShowHandle(flag:boolean) {
      this.isShowCusQr = flag
  }
  @Emit()
  joinGroupShowHandle(flag:boolean) {
      this.isShowGroupQr = flag
  }
  qRCodeFn() {
    let url = window.location.origin;
    this.QRCode.toDataURL(url, (err: any, url: any)=>{
        this.hostQr = url;
    });
  }
   // 打开售前在线客服
    openPreSaleSerLink() {
      console.log(this.preSaleSerObj_,'asgjklakg');
      if(Object.keys(this.preSaleSerObj_).length > 0) {
        if(this.preSaleSerObj_.htUrl) {
          if(this.snAndIsMyGoods_) {
            this.openOnlineService(this.preSaleSerObj_.htUrl,this.snAndIsMyGoods_.split('-')[0],this.snAndIsMyGoods_.split('-')[1])
          } else {
            this.openOnlineService(this.preSaleSerObj_.htUrl)
          }
        } else if(this.preSaleSerObj_.serQqNum) {
          window.open(`http://wpa.qq.com/msgrd?v=3&uin=${this.preSaleSerObj_.serQqNum}&site=qq&menu=yes`, '_blank')
        } else {            
            if(this.$store.state.web.distributorsObject && this.$store.state.web.distributorsObject.customerBlock.onlineSrvLink) {
                // window.open(this.$store.state.web.distributorsObject.customerBlock.onlineSrvLink, '_blank')
                this.openOnlineService(this.$store.state.web.distributorsObject.customerBlock.onlineSrvLink)
            }
        }
      } else {
          if(this.$store.state.web.distributorsObject && this.$store.state.web.distributorsObject.customerBlock.onlineSrvLink) {
            // window.open(this.$store.state.web.distributorsObject.customerBlock.onlineSrvLink, '_blank')
            this.openOnlineService(this.$store.state.web.distributorsObject.customerBlock.onlineSrvLink)
          } else if(this.$store.state.web.distributorsObject && this.$store.state.web.distributorsObject.customerBlock.serviceNum) {
            window.open(`http://wpa.qq.com/msgrd?v=3&uin=${this.$store.state.web.distributorsObject.customerBlock.serviceNum}&site=qq&menu=yes`, '_blank')
          }
      }
    }
    // 切换客服验证tab
    clickTabHandle(status:any) {
      this.inputStr = ''
      this.isCheckCurstomer = status
    }
    // 请求验证
    async goToCheck() {
      let noticeMsg = ''
      if(this.isCheckCurstomer) {
        if(!this.qqRegular.test((this.inputStr).trim())) {
          this.$message({
            // message: '经验证：12313131为假客服！',
            message: '请输入正确的客服QQ号！',
            offset: 530,
            duration: 5000,
            customClass: 'myMessageCss'
          })
          return
        } else {
          try {
            const {data} = await queryVerifyCustomerHttp({params:{queryType:1,queryValue:(this.inputStr).trim()}})
            if(data && data.code == 0 && Object.keys(data.data).length>0) {
              noticeMsg = `经验证：${this.inputStr}为真客服！`
            } else {
              noticeMsg = `经验证：${this.inputStr}为假客服！`
            }
            this.$message({
              message: noticeMsg,
              offset: 530,
              duration: 5000,
              customClass: 'myMessageCss'
            })
            this.inputStr = ''
          } catch (error) {
            console.log(error);
          }
        }
      } else {
        if(!this.inputStr) {
          this.$message({
            message: '请输入要查询的黑名单！',
            offset: 530,
            duration: 5000,
            customClass: 'myMessageCss'
          })
        } else {
          try {
            let distId = 110
            if(this.$store.state.web.distributorsObject.distId) {
              distId = this.$store.state.web.distributorsObject.distId
            }
            const {data} = await addBlackHttp({params:{pages:1,pageSize:10,distId:distId,keyWord:this.inputStr}})   
            if(data && data.code == 0 && data.data.list.length==0) {
              noticeMsg = '该用户未被拉入黑名单，如有后续违规请联系客服，避免线下交易！'
            } else {
              noticeMsg = '该用户已被拉入黑名单，请终止交易！'
            }
            this.$message({
              message: noticeMsg,
              offset: 530,
              duration: 5000,
              customClass: 'myMessageCss'
            })
            this.inputStr = ''  
          } catch (error) {
            console.log(error);
          }
        }
      }
    }
    // 微信验证另外处理
    async goToCheckWx() {
      let noticeMsg = ''
      if(this.inputWxStr.length < 6) {
        this.$message({
          message: '请输入正确的客服微信号！',
          offset: 530,
          duration: 2000,
          customClass: 'myMessageCss'
        })
      } else {
        try {
          const {data} = await queryVerifyCustomerHttp({params:{queryType:2,queryValue:this.inputWxStr}})
          if(data && data.code == 0 && Object.keys(data.data).length>0) {
            noticeMsg = `经验证：${this.inputWxStr}为真客服！`
          } else {
            noticeMsg = `经验证：${this.inputWxStr}为假客服！`
          }
          this.$message({
            message: noticeMsg,
            offset: 530,
            duration: 2000,
            customClass: 'myMessageCss'
          })
          this.inputWxStr = ''
        } catch (error) {
          console.log(error);
        }
      }
    }
    closeDialog() {
      this.isShowMask = false
    }
    showDialog(num:any) {
      if(num == 0) {
        this.isCheckCurstomer = false
      } else if(num == 1) {
        this.isCheckCurstomer = true
      }
      this.isShowMask = true
    }
}
